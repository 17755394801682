import { useNavigate } from '@remix-run/react';
import useSWR from 'swr';
import { match } from 'ts-pattern';

import {
  type DtoProgram,
  type EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { Loading } from '../Loading';
import { CreateProgramButton } from './CreateProgramButton';
import { ProgramV2Card } from './ProgramV2Card';
import { ReorderProgramsModal } from './ReorderProgramModal';

function ProgramSection(props: { programs: DtoProgram[] }): JSX.Element {
  const { programs } = props;
  const navigate = useNavigate();
  if (programs.length === 0) {
    return (
      <div className='w-full flex items-center justify-center text-secondary'>
        No Programs
      </div>
    );
  }
  return (
    <div className='flex items-center flex-wrap gap-10'>
      {programs.map((t) => (
        <ProgramV2Card
          key={t.id}
          program={t}
          onClick={() => navigate(`./${t.id}/edit`)}
        />
      ))}
    </div>
  );
}

function useAllPrograms() {
  return useSWR('/programs', async () => {
    const resp = await apiService.program.getPrograms();
    return resp.data;
  });
}

export function AdminProgramList(): JSX.Element {
  const swr = useAllPrograms();
  const navigate = useNavigate();

  const handleAdd = async (type: EnumsProgramType) => {
    const resp = await apiService.program.createProgram({
      name: '',
      type,
    });
    navigate(`./${resp.data.program.id}/edit`);
  };

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const onReorder = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ReorderProgramsModal
          activePrograms={swr.data?.activePrograms ?? []}
          inactivePrograms={swr.data?.inactivePrograms ?? []}
          onClose={p.internalOnCancel}
          onSave={() => {
            p.internalOnConfirm();
            swr.mutate();
          }}
        />
      ),
    });
  };

  const createProgramEnabled = useFeatureQueryParam('create-new-program');

  return (
    <div className='px-10 py-10 text-white'>
      <header className='flex justify-between items-center'>
        <div className='text-3.5xl font-bold'>Programs</div>
        {createProgramEnabled && (
          <CreateProgramButton onAdd={handleAdd} align='right' />
        )}
      </header>
      <section className='mt-10'>
        {match(swr)
          .when(
            () => swr.isLoading,
            () => <Loading />
          )
          .when(
            () => !!swr.error,
            () => (
              <div className='w-full flex items-center justify-center text-red-002'>
                {err2s(swr.error)}
              </div>
            )
          )
          .otherwise(() => (
            <div className='w-full flex flex-col items-center justify-center gap-10'>
              <div className='w-full flex flex-col gap-7.5'>
                <div className='w-full text-2xl font-medium flex items-center justify-between'>
                  <div>Active Programs</div>
                  <button
                    type='button'
                    className='btn-secondary text-base w-30 h-10'
                    onClick={onReorder}
                  >
                    Re-order
                  </button>
                </div>
                <ProgramSection programs={swr.data?.activePrograms ?? []} />
              </div>
              <div className='w-full flex flex-col gap-7.5'>
                <div className='text-2xl font-medium'>Inactive Programs</div>
                <ProgramSection programs={swr.data?.inactivePrograms ?? []} />
              </div>
            </div>
          ))}
      </section>
    </div>
  );
}
