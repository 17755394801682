import { type DtoProgram } from '@lp-lib/api-service-client/public';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { fromMediaDTO } from '../../utils/api-dto';
import { MediaUtils } from '../../utils/media';

export function ProgramV2Card(props: {
  program: DtoProgram;
  onClick?: () => void;
}): JSX.Element {
  const { program, onClick } = props;

  const coverImageUrl =
    MediaUtils.PickMediaUrl(
      fromMediaDTO(program.basicSettings?.cover?.media)
    ) ?? placeholder;

  return (
    <div
      className={`flex flex-col w-100 flex-shrink-0 ${
        onClick ? 'cursor-pointer' : ''
      }`}
      onClick={onClick}
    >
      <div className='aspect-w-16 aspect-h-9'>
        <img
          src={coverImageUrl}
          className={`object-cover rounded-xl flex-shrink-0 w-full h-full`}
          alt='cover'
        />
      </div>
      <div className='flex items-center'>
        <div className='text-white text-xl font-medium mt-1'>
          {program.name || 'Unnamed'}
        </div>
      </div>
      <div className='text-icon-gray text-sms h-10'>
        {program.basicSettings?.shortDescription ?? 'No description'}
      </div>
    </div>
  );
}
