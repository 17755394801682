import { useMemo, useRef } from 'react';
import {
  DndProvider,
  type DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { type SingleValue } from 'react-select';
import Select from 'react-select';

import { type DtoProgram } from '@lp-lib/api-service-client/public';

import placeholder from '../../assets/img/placeholder/game-cover.png';
import { useArrayState } from '../../hooks/useArrayState';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { fromMediaDTO } from '../../utils/api-dto';
import { canMove } from '../../utils/dnd';
import { MediaUtils } from '../../utils/media';
import { buildReactSelectStyles } from '../../utils/react-select';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { DeleteIcon } from '../icons/DeleteIcon';
import { MenuIcon } from '../icons/MenuIcon';

export function ProgramPicker(props: {
  programs: DtoProgram[];
  onChange: (value: DtoProgram) => void;
  filter?: (value: DtoProgram) => boolean;
}): JSX.Element {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<DtoProgram>({
        override: {
          control: { height: '100%' },
        },
      }),
    []
  );

  const handleSingleChange = (option: SingleValue<DtoProgram>) => {
    if (option) {
      props.onChange(option);
    }
  };

  return (
    <Select<DtoProgram, false>
      placeholder={`Select programs`}
      styles={styles}
      options={props.programs}
      classNamePrefix='select-box-v2'
      className='w-full'
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No programs matched';
      }}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) =>
        `${option.name ?? 'Unnamed'} (${option.type})`
      }
      formatOptionLabel={(option) => (
        <div>
          {option.name ?? 'Unnamed'} ({option.type})
        </div>
      )}
      onChange={handleSingleChange}
      filterOption={(option) => !!props.filter?.(option.data)}
    />
  );
}

type DragProgramEntry = {
  id: string;
  index: number;
};

function ReorderProgramEntry(props: {
  program: DtoProgram;
  index: number;
  onMove: (from: number, to: number) => void;
  onDelete: (program: DtoProgram) => void;
}) {
  const { program, index } = props;
  const coverImageUrl =
    MediaUtils.PickMediaUrl(
      fromMediaDTO(program.basicSettings?.cover?.media)
    ) ?? placeholder;

  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'program',
    hover(item: DragProgramEntry, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (canMove(dragIndex, hoverIndex, hoverBoundingRect, monitor)) {
        props.onMove(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });
  const [collected, drag, drapPreview] = useDrag({
    type: 'program',

    item: () => {
      return { id: program.id, index };
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 'opacity-40' : '',
    }),
  });

  drapPreview(drop(ref));

  return (
    <div
      ref={ref}
      className={`flex items-center gap-2 border border-secondary 
    bg-modal rounded-xl overflow-hidden ${collected.opacity} px-2`}
    >
      <button
        type='button'
        ref={drag}
        className='btn cursor-move flex-shrink-0 mr-2'
      >
        <MenuIcon />
      </button>
      <img className='w-16 h-9' src={coverImageUrl} alt='cover' />
      <div className='text-sms'>
        {program.name || 'Unnamed'} ({program.type})
      </div>
      <button
        type='button'
        className='btn flex-shrink-0 ml-auto text-red-002'
        onClick={() => props.onDelete(program)}
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

export function ReorderProgramsModal(props: {
  activePrograms: DtoProgram[];
  inactivePrograms: DtoProgram[];
  onClose: () => void;
  onSave: () => void;
}) {
  const [programs, setPrograms, dao] = useArrayState({
    init: props.activePrograms,
    compare: (a, b) => a.id === b.id,
  });

  const {
    call: submit,
    state: { state },
  } = useLiveAsyncCall(async () => {
    const programIds = programs.map((t) => t.id);
    await apiService.program.updateActivePrograms({
      programIds,
    });
    props.onSave();
  });

  return (
    <ModalWrapper containerClassName='w-160' borderStyle='gray'>
      <section className='flex flex-col items-center justify-center gap-5 p-10'>
        <header className='text-2xl font-medium'>Re-order Programs</header>
        <DndProvider backend={HTML5Backend}>
          <div className='text-base font-medium w-full'>Add a Program</div>
          <ProgramPicker
            programs={[...props.activePrograms, ...props.inactivePrograms]}
            onChange={(value) => setPrograms((prev) => [value, ...prev])}
            filter={(value) => !programs.find((t) => t.id === value.id)}
          />
          <div className='text-base font-medium w-full'>Active Programs</div>
          <div className='flex flex-col w-full gap-1'>
            {programs.map((t, i) => (
              <ReorderProgramEntry
                key={t.id}
                program={t}
                index={i}
                onMove={dao.moveItem}
                onDelete={dao.deleteItem}
              />
            ))}
          </div>
        </DndProvider>
        <footer className='flex justify-center items-center gap-5'>
          <button
            type='button'
            onClick={props.onClose}
            className='btn-secondary w-45 h-10'
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-45 h-10'
            disabled={state.isRunning}
            onClick={submit}
          >
            Save
          </button>
        </footer>
      </section>
    </ModalWrapper>
  );
}
