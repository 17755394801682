import { AdminProgramList } from '../components/Program';
import { AdminView } from '../pages/Admin/AdminView';

export function Component() {
  return (
    <AdminView className='bg-library-2023-07'>
      <AdminProgramList />;
    </AdminView>
  );
}
